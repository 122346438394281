<template>
	<errorContainer :error="error" :warning="warning">
		<b-modal
			header-class="header-class-modal-doc-package"
			ref="contact-form-modal"
			v-model="isOpen"
			size="lg"
			ok-only
			ok-variant="light"
			:ok-title="FormMSG(6, 'Close')"
			@ok="onCancel"
			@hidden="onCancel"
			modal-class="mui-animation"
			:fade="false"
		>
			<template #modal-title>
				{{ modalTitle }}
			</template>
			<div class="inline-block">
				<div class="d-flex flex-row justify-content-end pb-3">
					<b-col cols="3">
						<b-button block variant="primary" @click="addContact">
							{{ FormMSG(1, 'New contact') }}
						</b-button>
					</b-col>
				</div>
				<div class="d-flex flex-row">
					<list :supplier-id="supplierId" :supplier-type="supplierType" :refresh="refreshList" @list:edited="onEditForm" />
				</div>
			</div>
			<form-modal
				:supplier-id="supplierId"
				:supplier-type="supplierType"
				v-model="isOpenFormModal"
				:edit-contact="contact"
				:hide-cancel-action="isUpdateAction"
				@contact-form-modal:cancelled="onCloseFormModal"
				@contact-form-modal:confirmed="onConfirmFormModal"
				@contact-form-modal:updated="onUpdateFormModal"
			/>
		</b-modal>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import List from '@/components/Contact/List';
import FormModal from '@/components/Contact/FormModal';

export default {
	name: 'ContactListModal',
	mixins: [languageMessages],
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		modalTitle: {
			type: String,
			required: true,
			default: ''
		},
		supplierId: {
			type: [String, Number],
			required: true,
			default: '0'
		},
		supplierType: {
			type: Number,
			required: false,
			default: 2 // 0 = label, 1 = source, 2 = commission
		}
	},
	computed: {
		isOpen: {
			get() {
				return this.value;
			},
			set(value) {
				return value;
			}
		}
	},
	data() {
		return {
			error: {},
			warning: '',
			isOpenFormModal: false,
			isUpdateAction: true,
			refreshList: false,
			contact: null
		};
	},
	components: {
		FormModal,
		List
	},
	methods: {
		onCancel() {
			this.$emit('contact-list-modal:cancelled', false);
		},
		async addContact() {
			this.refreshList = false;
			this.contact = {};
			this.isUpdateAction = false;
			this.isOpenFormModal = true;
		},
		onCloseFormModal(payload) {
			this.isOpenFormModal = payload;
		},
		onConfirmFormModal(payload) {
			if (!this.isUpdateAction) {
				this.$bvModal
					.msgBoxConfirm(this.FormMSG(2, 'Please confirm if you want to reject this data.'), {
						title: this.FormMSG(3, 'Confirm'),
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'danger',
						okTitle: this.FormMSG(4, 'YES'),
						cancelTitle: this.FormMSG(5, 'NO'),
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false,
						noCloseOnBackdrop: true
					})
					.then(async (v) => {
						if (v) {
							this.isOpenFormModal = payload;
						}
					})
					.catch((err) => console.log(err));
			} else {
				this.isOpenFormModal = payload;
			}
		},
		onUpdateFormModal(payload) {
			this.refreshList = true;
		},
		onEditForm(payload) {
			this.contact = payload;
			this.isUpdateAction = true;
			this.isOpenFormModal = true;
		}
	}
};
</script>
