<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		ref="modal"
		v-model="isOpen"
		size="lg"
		:title="modalTitle"
		ok-variant="success"
		:ok-title="okTitle"
		@ok="onSubmit"
		@hidden="onCancel"
		ok-only
		modal-class="mui-animation"
		:fade="false"
	>
		<b-container fluid>
			<b-row>
				<b-col cols="12">
					<logo
						:GFId="supplierAttributeId"
						:GFType="imageChildType"
						:GFSubType="imageChildSubType"
						:is-single-file="true"
						accept-files=".jpg,.png,.jpeg"
						@image-child:removed="onRemoved"
						@image-child:added="onAdded"
					/>
				</b-col>
			</b-row>
		</b-container>
	</b-modal>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import Logo from '@/components/greenfilm/greenfilmdocs';

export default {
	name: 'SupplierAttributeLogoModal',
	mixins: [languageMessages],
	components: {
		Logo
	},
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		supplierAttributeId: {
			type: [String, Number],
			required: true,
			default: '0'
		},
		imageChildType: {
			type: String,
			required: false,
			default: 'supplier'
		},
		imageChildSubType: {
			type: String,
			required: false,
			default: 'supplierAttribute'
		}
	},
	computed: {
		isOpen: {
			get() {
				return this.value;
			},
			set(val) {
				return val;
			}
		},
		modalTitle() {
			return this.FormMSG(1, 'Logo');
		},
		okTitle() {
			return this.FormMSG(2, 'Ok');
		}
	},
	methods: {
		onSubmit() {
			this.$emit('supplier-attribute-logo:hidden', this.supplierAttributeId);
		},
		onCancel() {
			this.$emit('supplier-attribute-logo:hidden', this.supplierAttributeId);
		},
		onRemoved(payload) {
			this.$emit('supplier-attribute-logo:removed', payload);
		},
		onAdded(payload) {
			this.$emit('supplier-attribute-logo:added', payload);
		}
	}
};
</script>
