var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.error, warning: _vm.warning } },
    [
      _c(
        "b-modal",
        {
          ref: "contact-form-modal",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            size: "md",
            "ok-only": "",
            "ok-variant": "light",
            "ok-title": _vm.FormMSG(1, "Cancel"),
            "hide-header-close": !_vm.hideCancelAction,
            "no-close-on-esc": !_vm.hideCancelAction,
            "no-close-on-backdrop": !_vm.hideCancelAction,
            "modal-class": "mui-animation",
            fade: false,
          },
          on: { ok: _vm.onConfirm, hidden: _vm.onCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [_vm._v("\n\t\t\t" + _vm._s(_vm.modalTitle) + "\n\t\t")]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block" },
            [
              _c("contact-form", {
                attrs: {
                  "supplier-id": _vm.supplierId,
                  "supplier-type": _vm.supplierType,
                  "edit-contact": _vm.contact,
                  "is-update-action": _vm.hideCancelAction,
                },
                on: { "form:updated": _vm.formUpdated },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }