<template>
	<errorContainer :error="error" :warning="warning">
		<b-modal
			header-class="header-class-modal-doc-package"
			ref="contact-form-modal"
			v-model="isOpen"
			size="md"
			ok-only
			ok-variant="light"
			:ok-title="FormMSG(1, 'Cancel')"
			@ok="onConfirm"
			@hidden="onCancel"
			:hide-header-close="!hideCancelAction"
			:no-close-on-esc="!hideCancelAction"
			:no-close-on-backdrop="!hideCancelAction"
			modal-class="mui-animation"
			:fade="false"
		>
			<template #modal-title>
				{{ modalTitle }}
			</template>
			<div class="d-block">
				<contact-form
					:supplier-id="supplierId"
					:supplier-type="supplierType"
					:edit-contact="contact"
					:is-update-action="hideCancelAction"
					@form:updated="formUpdated"
				/>
			</div>
		</b-modal>
	</errorContainer>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import ContactForm from '@/components/Contact/Form';
import { isNil } from '@/shared/utils';

export default {
	name: 'ContactFormModal',
	mixins: [languageMessages],
	components: {
		ContactForm
	},
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false
		},
		editContact: {
			type: Object,
			required: false,
			default: () => {}
		},
		hideCancelAction: {
			type: Boolean,
			required: false,
			default: true
		},
		supplierId: {
			type: [String, Number],
			required: true,
			default: '0'
		},
		supplierType: {
			type: Number,
			required: false,
			default: 2 // 0 = label, 1 = source, 2 = commission
		}
	},
	computed: {
		isOpen: {
			get() {
				return this.value;
			},
			set(value) {
				return value;
			}
		},
		modalTitle() {
			return `${!this.hideCancelAction ? this.FormMSG(2, 'Add contact') : this.FormMSG(3, 'Update contact')}`;
		}
	},
	data() {
		return {
			error: {},
			warning: '',
			contact: null
		};
	},
	methods: {
		onConfirm(e) {
			e.preventDefault();
			this.$emit('contact-form-modal:confirmed', false);
		},
		onCancel() {
			this.$emit('contact-form-modal:cancelled', false);
		},
		formUpdated(payload) {
			this.$refs['contact-form-modal'].hide();
			this.$emit('contact-form-modal:updated', payload);
		}
	},
	watch: {
		editContact: {
			async handler(n) {
				if (!isNil(n)) {
					this.contact = n;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
